import * as React from "react"
import { Header, SingleVideo, Footer, BottomNavbar } from "../components/index"
import { Helmet } from "react-helmet"

import mockup from "../utils/data"

export default function videoPost({ pageContext }) {
  const { slug, id, title, titleRu, link, tags } = pageContext
  console.log("video open", pageContext)

  return (
    <div>
      <Helmet>
        <title>Esteekey - Single Videos</title>
        <meta name="description" content="Esteekey - #1 Fitness app for 2022" />
        <meta name="theme-color" content="#ffffff" data-react-helmet="true" />
      </Helmet>
      <Header />
      <div className="pt-[69px] xl:pt-0">
        <SingleVideo
          title={titleRu}
          overview={mockup.single_video.overview}
          tags={tags}
          videoUrl={pageContext?.link || mockup.single_video.video_url}
          videoPreview={mockup.single_video.video_preview}
          url={mockup.single_video.url}
          list={pageContext?.downloads || []}
        />
        <BottomNavbar />
        <Footer />
      </div>
    </div>
  )
}
